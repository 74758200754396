<template>
  <div>
    <div
      v-if="isFetching"
      class="global-spinner"
    >
      <div class="spinner">
        <div class="half" />
      </div>
    </div>
    <div v-else>
      <Row
        type="flex"
        :gutter="40"
      >
        <Col
          :xs="24"
          :md="12"
          class="small-widget-col"
        >
          <Card
            :bordered="false"
            shadow
          >
            <p slot="title">
              {{ domain.name }}
            </p>
            <div>
              <div class="definition-list">
                <dl>
                  <dt>{{ $t('global.domain.tier') }}</dt>
                  <dd>{{ domain.level }}</dd>
                  <dt>{{ $t('global.domain.pattern') }}</dt>
                  <dd>{{ domain.domain_pattern }}</dd>
                  <dt>{{ $t('global.domain.sub_lvl') }}</dt>
                  <dd>{{ domain.sld }}</dd>
                  <dt>{{ $t('global.table.vendor') }}</dt>
                  <dd v-if="domain.vendor_id">
                    <router-link
                      tag="a"
                      :to="{name: 'Vendors view', params: {name: domain.vendor.name}}"
                    >
                      <strong>{{ domain.vendor.name }}
                        <Icon type="md-link" />
                      </strong>
                    </router-link>
                  </dd>
                  <dd v-else>
                    {{ $t('global.none') }}
                  </dd>
                  <dt>{{ $t('global.vendor.last_u') }}</dt>
                  <dd>{{ lastUpdateAt }}</dd>
                </dl>
              </div>

              <div style="display: flex; align-items: center; justify-content: flex-end;">
                <Button
                  type="primary"
                  size="large"
                  class="btn btn--elevated"
                  @click="handleSubmit('domainsEditForm')"
                >
                  {{ $t('global.filters.update') }}
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Form
        class="stacked-form"
        ref="domainsEditForm"
        :model="formValidate"
        :rules="formRules"
        label-position="left"
        :label-width="100"
      >
        <Row
          type="flex"
          :gutter="40"
        >
          <Col
            :xs="24"
            :md="12"
            class="small-widget-col"
          >
            <Row>
              <div class="tile">
                <div class="tile-header tile-header--simple">
                  <span class="tile-header-title">{{ $t('global.table.vendor') }}</span>
                </div>
                <div class="tile-block">
                  <h4>{{ $t('global.domain.select_link_vendor') }} {{ domain.name }}.</h4>

                  <model-select
                    :options="options.vendors"
                    v-model="selectedVendor"
                    placeholder="Select a vendor"
                  />
                </div>
                <div class="tile-block">
                  <Card
                    dis-hover
                    shadow
                  >
                    <p slot="title">
                      {{ $t('global.domain.sub_for') }} {{ domain.name }}
                    </p>
                    <p>{{ $t('global.domain.check_sub') }} <strong>{{ selectedVendor.text }}</strong></p>
                    <span style="font-size: 0.85em;">{{ $t('global.domain.sub_in') }} <span style="color: #ca5872; font-weight: 500;">{{ $t('global.red') }}}</span> {{ $t('global.domain.not_link') }}</span>

                    <Divider class="transparent" />

                    <domains-tree
                      ref="domainsTreeComponent"
                      mode="DOMAIN"
                      :vendor-id="selectedVendor.value"
                      :domain="domain"
                    />
                  </Card>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {ModelSelect} from 'vue-search-select'
import DomainsTree from '@/views/Vendors/FicheVendor/DomainsTree'

export default {
  name: 'AdminDomainsView',
  components: {
    DomainsTree,
    ModelSelect
  },
  data () {
    return {
      isFetching: false,
      isRemoteSearch: false,
      selectedVendor: {},
      options: {
        vendors: []
      },
      formValidate: {
        vendorId: null,
        properties: []
      },
      domain: {},
      formRules: {
        // vendorId: [
        //   {
        //     type: 'integer',
        //     required: true,
        //     whitespace: true,
        //     message: 'Required',
        //     trigger: 'blur'
        //   },
        //   {
        //     validator (rule, value, callback) {
        //       console.log(value)
        //       if (!value) {
        //         return callback(new Error('Required'))
        //       }
        //
        //       callback()
        //     }
        //   }
        // ]
      }
    }
  },
  watch: {
    selectedVendor: {
      deep: true,
      handler () {
        !!this.$refs['domainsTreeComponent'] && this.$refs['domainsTreeComponent'].fetch()
      }
    }
  },
  computed: {
    lastUpdateAt () {
      if (this.domain.modified) {
        return moment(this.domain.modified).format('MMMM D YYYY, H:mm:ss')
      } else {
        return this.$t('global.no_update')
      }
    }
  },
  methods: {
    async fetchVendors () {
      try {
        const {data: response} = await this.$axios.get(`/vendors/remoteSearch`, {
          cache: {
            exclude: {query: true}
          }
        })

        if (response.success) {
          this.options = {
            vendors: response.data.map(x => ({value: x.id, text: x.name}))
          }
          this.options.vendors.unshift({value: null, text: '-- NO VENDOR or NOT APPLICABLE --'})
        } else {
          this.$Message.error(response.message)
        }
      } catch (err) {
        this.$Message.error(err.message)
      }
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    },
    async handleSubmit (name) {
      this.$axios.put('api/vendordomains/' + this.domain.id, {
        vendor_id: this.selectedVendor.value
      })

      await this.$refs.domainsTreeComponent.saveTree(true, this.selectedVendor.value)
      this.$Message.success('Domain updated with success!')
      this.$router.go(-1)
    },
    async fetch () {
      this.isFetching = true

      try {
        const {data: response} = await this.$axios.get(`api/vendordomains/${this.$route.query.id}`, {
          cache: {
            exclude: {query: true}
          }
        })

        if (response.success) {
          this.domain = {
            ...response.data,
            name: response.data.domain_pattern.replace(/\\/g, '').replace(/\$/g, '')
          }

          if (this.domain.vendor_id) {
            this.selectedVendor = {
              value: this.domain.vendor.id,
              text: this.domain.vendor.name
            }
          }
        } else {
          this.$Message.error(response.message)
        }
      } catch (err) {
        this.$Message.error(err.message)
      } finally {
        this.isFetching = false
      }
    }
  },
  mounted () {
    this.fetch()
    this.fetchVendors()
  }
}
</script>
